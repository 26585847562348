import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import NewFormContainer from "./NewFormContainer/NewFormContainer"

import * as styles from "./forms.module.scss"

const imgQuery = graphql`
  {
    img: file(relativePath: { eq: "Forms/planeta.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

type Props = {
  title?: string
  orderNum?: string
}

export function PlanetaForm({ title, orderNum }: Props) {
  const { img } = useStaticQuery(imgQuery)
  return (
    <NewFormContainer
      hasBirth
      hasCity
      imgData={img}
      title={title}
      sectionStyle={styles.planetaSection}
      formStyle={styles.planetaForm}
      orderNum={orderNum}
    />
  )
}
