import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import SpecialBanner from "./SpecialBanner"

const imgQuery = graphql`
  {
    mob: file(relativePath: { eq: "Banners/newPlanetacard/mob.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    tab: file(relativePath: { eq: "Banners/newPlanetacard/tab.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    desk: file(relativePath: { eq: "Banners/newPlanetacard/desk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    large: file(relativePath: { eq: "Banners/newPlanetacard/large.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

type Props = {
  logoInBanner?: string
  blackText?: boolean
  orderNum?: string
}

const offer = (
  <>
    ХАЛВА PLANETACARD — <br /> всего одна карта <br /> для всего
  </>
)

const subTitle = (
  <>
    Все преимущества классической ХАЛВЫ <br /> плюс уникальные условия на покупки в
    ТРЦ&nbsp;«Планета»! <br /> Карта выдаётся только в ТРЦ&nbsp;«Планета»
  </>
)

export default function Banner({ logoInBanner, blackText, orderNum }: Props) {
  const imgData = useStaticQuery(imgQuery)

  return (
    <SpecialBanner
      imgData={imgData}
      title={offer}
      subTitle={subTitle}
      logoInBanner={logoInBanner}
      blackText={blackText}
      orderNum={orderNum}
    />
  )
}
